export default {
  props: {
    aspectRatio: {
      type: Number,
      default: 1 / 1,
    },

    initialAspectRatio: {
      type: Number,
      default: 1 / 1,
    },

    disableCropping: {
      type: Boolean,
      default: false,
    },

    viewMode: {
      type: Number,
      default: 3,
      validator: (value) => value >= 0 && value <= 3,
    },
  },
}
