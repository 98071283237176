import ImageMixin from '@/utils/mixins/Image'

export default {
  mixins: [ImageMixin],
  props: {
    image: {
      type: [Object, String],
      default: () => null,
    },

    canUpload: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
  },
}
