<template>
  <v-dialog
    :value="value"
    content-class="view-image"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-btn dark fab @click="close" class="view-image__close">
      <v-icon>{{ icons.close }}</v-icon>
    </v-btn>
    <v-card class="view-image__border">
      <img v-if="currentImageUrl" :src="currentImageUrl" />
    </v-card>
  </v-dialog>
</template>
<script>
/**
 * ==================================================================================
 * View image on modal
 * ==================================================================================
 **/

import { mdiClose } from '@mdi/js'

export default {
  name: 'ViewImage',
  props: {
    value: {
      type: [String, Boolean, Object],
      default: () => null,
    },
  },

  data() {
    return {
      icons: {
        close: mdiClose,
      },
    }
  },

  computed: {
    currentImageUrl() {
      if (!this.value) return false
      if (typeof this.value === 'string' || this.value instanceof String)
        return this.value
      return typeof this.value === 'object'
        ? this.value.full_url || this.value.url
        : false
    },
  },

  methods: {
    close() {
      this.$emit('input', null)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .view-image {
  width: auto;
  height: 100%;
  box-shadow: none;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  &__border {
    height: 100%;
    text-align: center;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
