<template>
  <div class="image-cropper">
    <input
      type="file"
      ref="filePicker"
      @change="onFileChange"
      style="display: none"
      accept="image/*"
    />
    <v-dialog v-model="show" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Crop Image</v-card-title>
        <v-card-text>
          <div v-if="imgSrc">
            <vue-cropper
              ref="cropper"
              :guides="true"
              drag-mode="crop"
              :src="imgSrc"
              :aspect-ratio="aspectRatio"
              :initial-aspect-ratio="initialAspectRatio"
              :view-mode="viewMode"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" v-if="imgSrc" @click="crop">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Image Cropper
 * ==================================================================================
 **/

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import ImageMixin from '@/utils/mixins/Image'
import ImageCropperMixin from '@/utils/mixins/ImageCropper'
import Compressor from 'compressorjs'
import UPLOAD_IMAGE_SIZE from '@/utils/enums/UploadImageSize'

export default {
  components: {
    VueCropper,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin, ImageMixin, ImageCropperMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
      default: UPLOAD_IMAGE_SIZE.OTHER_IMAGE.width,
    },

    maxHeight: {
      type: Number,
      default: UPLOAD_IMAGE_SIZE.OTHER_IMAGE.height,
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    convertSize: {
      type: Number,
      default: 2, // 2MB
    },
  },

  data() {
    return {
      show: this.value,
      imgSrc: null,
    }
  },

  mounted() {
    document.body.onfocus = () => {
      if (!this.imgSrc && !this.show) {
        this.$emit('input', false)
      }
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.resetImage()
        this.selectImage()

        /**
         * IMPORTANT: As we cannot catch the event where the user
         * cancels the file selection, we trigger back the value
         * to false to enable selection again
         */
        setTimeout(() => {
          this.$emit('input', false)
        }, 250)
      }
    },

    imgSrc(value) {
      if (value) {
        this.toggleCropper()
      }
    },
  },

  methods: {
    selectImage() {
      if (this.$refs.filePicker) {
        this.$refs.filePicker.click()
      }
    },

    resetImage() {
      this.imgSrc = null

      if (this.$refs.filePicker) {
        this.$refs.filePicker.value = null
      }
    },

    toggleCropper(show = true) {
      this.show = show
    },

    crop() {
      let canvas = this.$refs.cropper.getCroppedCanvas()
      canvas.toBlob((blob) => {
        this.compressor(blob)
      })
    },

    close() {
      this.show = false
      this.resetImage()
    },

    onChange(value, isOriginal = false) {
      this.close()
      if (isOriginal) {
        this.compressor(value)
      } else {
        this.$emit('change', value)
      }
    },

    async onFileChange(event) {
      const file = event.target.files[0]

      if (file) {
        if (this.disableCropping) {
          const reader = new FileReader()

          reader.onload = (event) => {
            const base64Data = event.target.result
            const blob = this.base64ToBlob(base64Data)
            // Now you can use the blob as needed
            this.onChange(blob, true)
          }

          reader.readAsDataURL(file)
        } else {
          await this.toBase64(file)
            .then((image) => {
              // if (this.disableCropping) {
              //   const blob = this.base64ToBlob(image)
              //   this.onChange(blob, true)
              // } else {
              this.show = true
              this.imgSrc = image.url
              // }
            })
            .catch((error) => {
              this.showSnackbar(error.message, false)
              this.close()
            })
        }
      } else {
        this.close()
      }
    },

    compressor(blob) {
      const self = this
      let instance = new Compressor(blob, {
        quality: 0.8, // The quality of the output image
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        width: this.width,
        height: this.height,
        convertTypes: ['image/png', 'image/webp'],
        convertSize: this.convertSize * 1.4 * 1000 * 1000, // If the image over convertSize, it will convert to JPEG type to save the size. The quality 0.8 has compression ratio ~ 46.41% => this.convertSize * 1.5
        success(compressImage) {
          self.onChange({
            file: compressImage,
            url: URL.createObjectURL(blob),
          })
        },
        error(err) {
          console.log(err.message)
        },
      })

      return instance
    },

    base64ToBlob(base64Data) {
      const byteString = atob(base64Data.split(',')[1])
      const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]

      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ab], { type: mimeString })
    },
  },
}
</script>
<style lang="scss" scoped>
.image-cropper {
  //
}
</style>
