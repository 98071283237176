<template>
  <v-overlay
    v-if="value"
    :loading="loading"
    absolute
    opacity="0.2"
    class="loading"
    v-bind="$attrs"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
/**
 * ==================================================================================
 * Circular Loader
 * ==================================================================================
 **/

export default {
  name: 'Loading',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
