<template>
  <div class="upload-button">
    <v-btn
      text
      class="text-capitalize font-weight-bold text-light-blue"
      :disabled="disabled"
      @click="toggle"
    >
      <img src="@/assets/icons/icon-image.svg" class="mr-2" alt />Upload Image
    </v-btn>

    <image-cropper
      v-model="showUpload"
      :aspect-ratio="aspectRatio"
      :initial-aspect-ratio="initialAspectRatio"
      :disable-cropping="disableCropping"
      :maxWidth="maxWidth"
      :maxHeight="maxHeight"
      :width="width"
      :height="height"
      @change="onChange"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * File Upload: Upload Button
 * ==================================================================================
 **/

import ImageCropper from '@/components/modals/ImageCropper'
import ImageCropperMixin from '@/utils/mixins/ImageCropper'
import FileUploadMixin from '@/utils/mixins/FileUpload'
import SnackbarMixin from '@/utils/mixins/Snackbar'

export default {
  name: 'UploadButton',
  components: {
    ImageCropper,
  },

  mixins: [SnackbarMixin, FileUploadMixin, ImageCropperMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
    },

    maxHeight: {
      type: Number,
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },
  },

  data() {
    return {
      showUpload: false,
    }
  },

  methods: {
    toggle() {
      this.showUpload = true
    },

    onChange(image) {
      // let self = this
      // const img = new Image()
      // img.onload = function () {
      //   if (this.width > self.maxWidth) {
      //     self.showSnackbar(
      //       `Ideal image size ${self.maxWidth}px x ${self.maxWidth}px`,
      //       false
      //     )
      //   } else {
      //     self.$emit('input', image)
      //   }
      // }
      // img.src = image.url
      this.$emit('input', image)
    },
  },
}
</script>
