<template>
  <div
    v-if="url"
    class="uploaded-image"
    :style="{
      backgroundImage: `url('${url}')`,
    }"
    @click="selectImage"
  />
</template>
<script>
/**
 * ==================================================================================
 * File Upload: Uploaded Image
 * ==================================================================================
 **/

export default {
  name: 'UploadedImage',
  props: {
    image: {
      type: [String, Object, Boolean],
      default: () => null,
    },
  },

  computed: {
    url() {
      if (!this.image) return null
      if (typeof this.image === 'string' || this.image instanceof String)
        return this.image
      return typeof this.image === 'object'
        ? this.image.full_url || this.image.url
        : null
    },
  },

  methods: {
    selectImage() {
      this.$emit('select-image', this.image)
    },
  },
}
</script>
<style lang="scss" scoped>
.uploaded-image {
  width: 100%;
  max-height: 140px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
</style>
