<template>
  <div
    class="rating"
    :class="{
      'rating--sm': small,
    }"
  >
    <v-icon class="rating__icon">{{ icon }}</v-icon>
    <p class="rating__value">{{ rating }}</p>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Rating
 * ==================================================================================
 **/

import {
  mdiThumbUp,
  mdiThumbUpOutline,
  mdiThumbDown,
  mdiThumbDownOutline,
} from '@mdi/js'

export default {
  name: 'Rating',
  props: {
    thumbs: {
      type: Boolean,
      required: true,
    },

    rating: {
      type: Number,
      default: 0,
    },

    highlight: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: {
        thumbsUpHighlighted: mdiThumbUp,
        thumbsUp: mdiThumbUpOutline,
        thumbsDownHighlighted: mdiThumbDown,
        thumbsDown: mdiThumbDownOutline,
      },
    }
  },

  computed: {
    icon() {
      return this.isThumbsUp ? this.thumbsUpIcon : this.thumbsDownIcon
    },

    thumbsUpIcon() {
      return this.highlight
        ? this.icons.thumbsUpHighlighted
        : this.icons.thumbsUp
    },

    thumbsDownIcon() {
      return this.highlight
        ? this.icons.thumbsDownHighlighted
        : this.icons.thumbsDown
    },

    isThumbsUp() {
      return !!this.thumbs
    },
  },
}
</script>
<style lang="scss" scoped>
.rating {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    margin-right: 5px;
  }

  &__value {
    margin-bottom: 0;
  }
}

.rating--sm {
  .rating__icon {
    width: 18px;
  }
}
</style>
