<template>
  <div class="perk-rating">
    <Rating
      :thumbs="true"
      :rating="thumbsUp"
      :highlight="isRatingUp"
      :small="small"
    />
    <Rating
      :thumbs="false"
      :rating="thumbsDown"
      :highlight="isRatingUp === false"
      :small="small"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Perk Rating
 * ==================================================================================
 **/
import Rating from '@/components/common/Rating'

export default {
  name: 'PerkRating',
  components: {
    Rating,
  },

  props: {
    perk: {
      type: Object,
      default: () => null,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    thumbsUp() {
      return this.perk ? this.perk.likes_count : 0
    },

    thumbsDown() {
      return this.perk ? this.perk.dislikes_count : 0
    },

    isRatingUp() {
      return this.perk ? this.perk.isRatingUp : null
    },
  },
}
</script>
<style lang="scss" scoped>
.perk-rating {
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
