<template>
  <div class="create-perk">
    <PerkAppbar />
    <div class="ma-4">
      <PerkForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Perk
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import PerkAppbar from '@/views/Home/Perks/components/PerkAppbar'
import PerkForm from '@/views/Home/Perks/components/PerkForm'

export default {
  components: {
    PerkAppbar,
    PerkForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'perk/clearPerkDetails',
    }),
  },
}
</script>
<style lang="scss">
.create-perk {
  //
}
</style>
